import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import type { Observable } from 'rxjs';
import { map, of, Subject, switchMap, take, tap, timer } from 'rxjs';
import type { ChainExchangesData } from '@dextools/blockchains';
import { ExchangeApiService } from '@dextools/blockchains/services';
import { ExchangeUtil, Chain, EXCHANGE_LIST_FALLBACK, chainList } from '@dextools/blockchains';

@Injectable({
  providedIn: 'root',
})
export class ExchangesResolver {
  private readonly _resolved$ = new Subject<void>();
  public resolved$ = this._resolved$.asObservable();
  private _allChainExchangesFetched = false;

  public constructor(private readonly _exchangeApiService: ExchangeApiService) {}

  /**
   * IMPORTANT: this method should be called only when the app starts (in the AppModule).
   * It fetches the exchanges for the current chain only. If no chain defined, then the exchanges of all chains will be fetched.
   *
   * @param chain - The current chain or `null` in case of no chain defined.
   *
   * @returns An observable that emits the exchanges of the current chain (if a chain was given), or the exchanges of all chains.
   */
  public initialResolution(chain: Chain | null): Observable<ChainExchangesData> {
    // eslint-disable-next-line unicorn/prefer-ternary
    if (chain) {
      // current chain only
      return this._fetchExchangesByChain(chain).pipe(
        tap(() => {
          // fetch the exchanges of ALL chains asynchronously afterwards
          timer(1_000)
            .pipe(
              take(1),
              switchMap(() => this._fetchExchangesFromAllChains()),
            )
            .subscribe();
        }),
      );
    } else {
      return this._fetchExchangesFromAllChains();
    }
  }

  public resolve: ResolveFn<ChainExchangesData> = (route: ActivatedRouteSnapshot) => {
    const chainInUrl = ((route.params['chain'] as Chain) || (route.params['chainOrExchange'] as Chain)) ?? null;

    if (chainInUrl) {
      const exchangesCurrentChain = ExchangeUtil.chainExchangeList[chainInUrl] ?? [];

      if (ExchangeUtil.isExchangeListExpired || exchangesCurrentChain.length <= EXCHANGE_LIST_FALLBACK[chainInUrl].exchanges.length) {
        this._allChainExchangesFetched = false;
        return this._fetchExchangesByChain(chainInUrl).pipe(
          tap(() => this._resolved$.next()),
          tap(() => {
            // fetch the exchanges of ALL chains asynchronously afterwards
            timer(1_000)
              .pipe(
                take(1),
                switchMap(() => this._fetchExchangesFromAllChains()),
              )
              .subscribe();
          }),
        );
      }
    } else {
      if (ExchangeUtil.isExchangeListExpired || !this._allChainExchangesFetched) {
        return this._fetchExchangesFromAllChains().pipe(tap(() => this._resolved$.next()));
      }
    }

    return of(ExchangeUtil.chainExchangeList).pipe(tap(() => this._resolved$.next()));
  };

  private _fetchExchangesFromAllChains(): Observable<ChainExchangesData> {
    return this._exchangeApiService.fetchExchanges().pipe(
      map((chainExchanges) => {
        for (const chain of chainList) {
          if (chainExchanges[chain]) {
            chainExchanges = this._addChainToChainExchangesList(chainExchanges, chain);
          } else {
            chainExchanges[chain] = [...EXCHANGE_LIST_FALLBACK[chain].exchanges];
          }
        }
        ExchangeUtil.updateExchangesCheckDate();
        ExchangeUtil.chainExchangeList = chainExchanges;
        this._allChainExchangesFetched = true;
        return chainExchanges;
      }),
      take(1),
    );
  }

  private _fetchExchangesByChain(chain: Chain): Observable<ChainExchangesData> {
    return this._exchangeApiService.fetchExchanges(chain).pipe(
      take(1),
      map((chainExchanges) => {
        for (const itemChain of chainList) {
          if (itemChain === chain && chainExchanges[itemChain]) {
            chainExchanges = this._addChainToChainExchangesList(chainExchanges, itemChain);
          } else {
            chainExchanges[itemChain] = [...EXCHANGE_LIST_FALLBACK[itemChain].exchanges];
          }
        }
        ExchangeUtil.updateExchangesCheckDate();
        ExchangeUtil.chainExchangeList = chainExchanges;
        return chainExchanges;
      }),
    );
  }

  private _addChainToChainExchangesList(chainExchanges: ChainExchangesData, chain: Chain): ChainExchangesData {
    // Exchanges fall back are added when a chain does not come in the response of the list of exchanges recovered from the call.
    chainExchanges[chain] = chainExchanges[chain] ?? [...EXCHANGE_LIST_FALLBACK[chain].exchanges];
    return chainExchanges;
  }
}
