import { Chain } from '../models/chain.model';
import { EXCHANGE_UNKNOWN } from '../models/exchange.model';
import type { ChainExchanges } from '../models/shared-api/base-shared-api.model';

const UNISWAP_LOGO_URL = '/resources/exchanges/uniswap.png';
const UNISWAP_URL_SWAP = 'https://app.uniswap.org/#/swap';
const UNISWAP_URL = 'https://uniswap.org/';
const SUSHISWAP_LOGO_URL = '/resources/exchanges/sushiswap.png';
const SUSHISWAP_URL_SWAP = 'https://app.sushi.com/swap';
const SUSHISWAP_URL = 'https://sushi.com/';

// IMPORTANT: at least one exchange per chain should be added here as fallback
// This is useful to improve first load of the app when there are no exchanges in LocalStorage yet
/* eslint-disable @typescript-eslint/no-explicit-any */
export const EXCHANGE_LIST_FALLBACK: ChainExchanges = {
  [Chain.Ethereum]: {
    exchanges: [
      {
        slug: 'univ2',
        isDefault: true,
        name: 'Uniswap V2',
        logo: UNISWAP_LOGO_URL,
        chainId: Chain.Ethereum,
        urlInfo: 'https://v2.info.uniswap.org/pair/',
        urlSwap: UNISWAP_URL_SWAP,
        version: 'v2',
        pairDefault: '0xa29fe6ef9592b5d408cca961d0fb9b1faf497d6d',
        backgroundColor: '#ff2d79',
        textColor: '#fff',
        limitBotSupported: true,
      },
      {
        slug: 'univ3',
        isDefault: false,
        name: 'Uniswap V3',
        logo: UNISWAP_LOGO_URL,
        chainId: Chain.Ethereum,
        urlInfo: 'https://v3.info.uniswap.org/#/pools/',
        urlSwap: UNISWAP_URL_SWAP,
        version: 'v3',
        pairDefault: '0xa29fe6ef9592b5d408cca961d0fb9b1faf497d6d',
        backgroundColor: '#ff2d79',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Binance]: {
    exchanges: [
      {
        slug: 'pancake',
        isDefault: true,
        name: 'PancakeSwap V1',
        logo: '/resources/exchanges/pancakeswap.png',
        chainId: Chain.Binance,
        urlInfo: 'https://pancakeswap.info',
        urlSwap: 'https://v1exchange.pancakeswap.finance/#/swap',
        version: 'v1',
        backgroundColor: '#f8b612',
        textColor: '#5a5a5a',
        pairDefault: '0x4b729d5d871057f3a9c424792729217cde72410d',
        limitBotSupported: true,
      },
      {
        slug: 'pancakev2',
        isDefault: false,
        name: 'PancakeSwap V2',
        logo: '/resources/exchanges/pancakeswap.png',
        chainId: Chain.Binance,
        urlInfo: 'https://pancakeswap.info',
        urlSwap: 'https://pancakeswap.finance/swap',
        version: 'v2',
        backgroundColor: '#f8b612',
        textColor: '#5a5a5a',
        pairDefault: '0x4b729d5d871057f3a9c424792729217cde72410d',
        limitBotSupported: true,
      },
    ],
  },
  [Chain.Polygon]: {
    exchanges: [
      {
        slug: 'quick',
        isDefault: true,
        name: 'QuickSwap',
        logo: '/resources/exchanges/quickswap.png',
        chainId: Chain.Polygon,
        urlInfo: 'https://info.quickswap.exchange/',
        urlSwap: 'https://quickswap.exchange/#/swap',
        version: null as any,
        backgroundColor: '#1a3b8d',
        textColor: '#fff',
        pairDefault: '0xadbf1854e5883eb8aa7baf50705338739e558e5b',
      },
      {
        slug: 'univ3poly',
        isDefault: false,
        name: 'Uniswap',
        logo: UNISWAP_LOGO_URL,
        chainId: Chain.Polygon,
        urlInfo: 'https://info.uniswap.org/#/polygon/',
        urlSwap: UNISWAP_URL_SWAP,
        version: null as any,
        backgroundColor: '#ff2d79',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Fantom]: {
    exchanges: [
      {
        slug: 'spooky',
        isDefault: true,
        name: 'Spookyswap',
        logo: '/resources/exchanges/Spookyswap.webp',
        chainId: Chain.Fantom,
        urlInfo: 'https://info.spookyswap.finance/',
        urlSwap: 'https://spookyswap.finance/swap',
        version: null as any,
        backgroundColor: '#8c8ce3',
        textColor: '#fff',
        pairDefault: '0xf0702249f4d3a25cd3ded7859a165693685ab577',
      },
    ],
  },
  [Chain.Cronos]: {
    exchanges: [
      {
        slug: 'crona',
        isDefault: true,
        name: 'CronaSwap',
        logo: '/resources/exchanges/cronaswap.svg',
        chainId: Chain.Cronos,
        urlInfo: 'https://app.cronaswap.org/',
        urlSwap: 'https://app.cronaswap.org/swap',
        version: null as any,
        backgroundColor: '#3f79ed',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Avalanche]: {
    exchanges: [
      {
        slug: 'traderjoe',
        isDefault: true,
        name: 'Trader Joe',
        logo: '/resources/exchanges/traderjoe.png',
        chainId: Chain.Avalanche,
        urlInfo: 'https://traderjoexyz.com/',
        urlSwap: 'https://traderjoexyz.com/#/trade',
        version: null as any,
        backgroundColor: '#14163b',
        textColor: '#fff',
        pairDefault: '0xa389f9430876455c36478deea9769b7ca4e3ddb1',
        limitBotSupported: true,
      },
    ],
  },
  [Chain.Oasis]: {
    exchanges: [
      {
        slug: 'dex513653',
        isDefault: true,
        name: 'YuzuSwap',
        logo: '/resources/exchanges/yuzuswap.svg',
        chainId: Chain.Oasis,
        urlInfo: 'https://yuzu-swap.com/',
        urlSwap: 'https://app.yuzu-swap.com/#/swap',
        version: null as any,
        backgroundColor: '#de2549',
        textColor: '#FFF',
        pairDefault: '0xfb9bd52abe613a5c4a20e9fc09462a2ec5f2d1b2',
      },
    ],
  },
  [Chain.Velas]: {
    exchanges: [
      {
        slug: 'dex7f35e3',
        isDefault: true,
        name: 'WagyuSwap',
        logo: '/resources/exchanges/wagyuswap.png',
        chainId: Chain.Velas,
        urlInfo: 'https://www.wagyuswap.app/',
        urlSwap: 'https://exchange.wagyuswap.app/swap',
        version: null as any,
        backgroundColor: '#ee4a63',
        textColor: '#FFF',
        pairDefault: '0x8e2b762bee3e2bf2c8fb0cdd04274042748d6c23',
      },
    ],
  },
  [Chain.Kucoin]: {
    exchanges: [
      {
        slug: 'dex2e65ae',
        isDefault: true,
        name: 'Kuswap',
        logo: '/resources/exchanges/ku.png',
        chainId: Chain.Kucoin,
        urlInfo: 'https://kuswap.finance/',
        urlSwap: 'https://kuswap.finance/#/swap',
        version: null as any,
        pairDefault: '0x6c31e0f5c07b81a87120cc58c4dcc3fbafb00367',
        backgroundColor: '#03a9f4',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Metis]: {
    exchanges: [
      {
        slug: 'dex9dff9f',
        isDefault: true,
        name: 'Netswap',
        logo: '/resources/exchanges/net.svg',
        chainId: Chain.Metis,
        urlInfo: 'https://netswap.io/#/home',
        urlSwap: 'https://netswap.io/#/swap',
        version: null as any,
        pairDefault: '0x5ae3ee7fbb3cb28c17e7adc3a6ae605ae2465091',
        backgroundColor: '#c42a61',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Optimism]: {
    exchanges: [
      {
        slug: 'dex31f984',
        isDefault: true,
        name: 'Uniswap',
        logo: UNISWAP_LOGO_URL,
        chainId: Chain.Optimism,
        urlInfo: UNISWAP_URL,
        urlSwap: UNISWAP_URL_SWAP,
        version: null as any,
        pairDefault: '0xc858a329bf053be78d6239c4a4343b8fbd21472b',
        backgroundColor: '#ff007a',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Arbitrum]: {
    exchanges: [
      {
        slug: 'dex31f984',
        isDefault: true,
        name: 'Uniswap',
        logo: UNISWAP_LOGO_URL,
        chainId: Chain.Arbitrum,
        urlInfo: UNISWAP_URL,
        urlSwap: UNISWAP_URL_SWAP,
        version: null as any,
        pairDefault: '0x905dfcd5649217c42684f23958568e533c711aa3',
        backgroundColor: '#ff007a',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Celo]: {
    exchanges: [
      {
        slug: 'dexbc74c4',
        isDefault: true,
        name: 'Sushiswap',
        logo: SUSHISWAP_LOGO_URL,
        chainId: Chain.Celo,
        urlInfo: SUSHISWAP_URL,
        urlSwap: SUSHISWAP_URL_SWAP,
        version: null as any,
        pairDefault: '0x1e593f1fe7b61c53874b54ec0c59fd0d5eb8621e', // CELO/cUSD at ubeswap
        backgroundColor: '#3e3e7c',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Telos]: {
    exchanges: [
      {
        slug: 'dexbc74c4',
        isDefault: true,
        name: 'Sushiswap',
        logo: SUSHISWAP_LOGO_URL,
        chainId: Chain.Telos,
        urlInfo: SUSHISWAP_URL,
        urlSwap: SUSHISWAP_URL_SWAP,
        version: null as any,
        pairDefault: '0x3eff1f57305b3d685ed1dbbc3e287d534f3d0bfc', // CELO/cUSD at ubeswap
        backgroundColor: '#3e3e7c',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Aurora]: {
    exchanges: [
      {
        slug: 'dexbc74c4',
        isDefault: true,
        name: 'Trisolaris',
        logo: '/resources/exchanges/trisolaris.svg',
        chainId: Chain.Aurora,
        urlInfo: 'https://www.trisolaris.io/',
        urlSwap: 'https://www.trisolaris.io/#/swap',
        version: null as any,
        pairDefault: '0x03b666f3488a7992b2385b12df7f35156d7b29cd',
        backgroundColor: '#1350ff',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Moonbeam]: {
    exchanges: [
      {
        slug: 'dexdacc0e',
        isDefault: true,
        name: 'Stellaswap',
        logo: '/resources/exchanges/stella.png',
        chainId: Chain.Moonbeam,
        urlInfo: 'https://stellaswap.com/',
        urlSwap: 'https://app.stellaswap.com/exchange/swap',
        version: null as any,
        pairDefault: '0x555b74dafc4ef3a5a1640041e3244460dc7610d1',
        backgroundColor: '#E4107B',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Moonriver]: {
    exchanges: [
      {
        slug: 'dex5a1b68',
        isDefault: true,
        name: 'Solarbeam',
        logo: '/resources/exchanges/solarbeam.png',
        chainId: Chain.Moonriver,
        urlInfo: 'https://solarbeam.io/',
        urlSwap: 'https://app.solarbeam.io/exchange/swap',
        version: null as any,
        pairDefault: '0xe537f70a8b62204832b8ba91940b77d3f79aeb81',
        backgroundColor: '#E4107B',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Harmony]: {
    exchanges: [
      {
        slug: 'dexbc74c4',
        isDefault: true,
        name: 'Sushiswap',
        logo: SUSHISWAP_LOGO_URL,
        chainId: Chain.Harmony,
        urlInfo: SUSHISWAP_URL,
        urlSwap: SUSHISWAP_URL_SWAP,
        version: null as any,
        pairDefault: '0xbf255d8c30dbab84ea42110ea7dc870f01c0013a',
        backgroundColor: '#3E3E7C',
        textColor: '#fff',
      },
    ],
  },

  [Chain.Astar]: {
    exchanges: [
      {
        slug: 'dex10a03e',
        isDefault: true,
        name: 'Arthswap',
        logo: '/resources/exchanges/arthswap.svg',
        chainId: Chain.Astar,
        urlInfo: 'https://app.arthswap.org/',
        urlSwap: 'https://app.arthswap.org/#/swap',
        version: null as any,
        pairDefault: '0x806f746a7c4293092ac7aa604347be123322df1e',
        backgroundColor: '#1B6DC1',
        textColor: '#fff',
      },
    ],
  },

  [Chain.Fuse]: {
    exchanges: [
      {
        slug: 'dex294f3a',
        isDefault: true,
        name: 'Voltage Finance',
        logo: '/resources/exchanges/voltage.svg',
        logoStyles: '{ background-color: #242637 }',
        chainId: Chain.Fuse,
        urlInfo: 'https://app.voltage.finance/index.html#/home',
        urlSwap: 'https://app.voltage.finance/index.html#/swap',
        version: null as any,
        pairDefault: '0x9f17b1895633e855b8b1c1d0ade9b3b72eb0590c',
        backgroundColor: '#c1f23b',
        textColor: '#000',
      },
    ],
  },

  [Chain.Iotex]: {
    exchanges: [
      {
        slug: 'dexa58b9d',
        isDefault: true,
        name: 'Mimo',
        logo: '/resources/exchanges/mimo.svg',
        chainId: Chain.Iotex,
        urlInfo: 'https://v2.info.mimo.exchange/',
        urlSwap: 'https://mimo.exchange/#/swap',
        version: null as any,
        pairDefault: '0x6bdefe87c4b18726002d3505b3251c89c8004c6a',
        backgroundColor: '#00e100',
        textColor: '#000',
      },
    ],
  },

  [Chain.Oktc]: {
    exchanges: [
      {
        slug: 'dex4d4afe',
        isDefault: true,
        name: 'Cherryswap',
        logo: '/resources/exchanges/cherry.svg',
        chainId: Chain.Oktc,
        urlInfo: 'https://okinfo.cherryswap.net/',
        urlSwap: 'https://www.cherryswap.net/#/swap',
        version: null as any,
        pairDefault: '0xf3098211d012ff5380a03d80f150ac6e5753caa8',
        backgroundColor: '#e55268',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Heco]: {
    exchanges: [
      {
        slug: 'dex22d941',
        isDefault: true,
        name: 'MDEX',
        logo: '/resources/exchanges/mdex.svg',
        chainId: Chain.Heco,
        urlInfo: 'https://ht.mdex.com/',
        urlSwap: 'https://ht.mdex.com/#/swap',
        version: null as any,
        pairDefault: '0x499b6e03749b4baf95f9e70eed5355b138ea6c31',
        backgroundColor: '#000d49',
        textColor: '#fff',
      },
    ],
  },

  [Chain.Milkomeda]: {
    exchanges: [
      {
        slug: 'dexa5ad29',
        isDefault: true,
        name: 'Milkyswap',
        logo: '/resources/exchanges/milky.svg',
        chainId: Chain.Milkomeda,
        urlInfo: 'https://www.milkyswap.exchange/',
        urlSwap: 'https://www.milkyswap.exchange/swap',
        version: null as any,
        pairDefault: '0x0b46ad9e9b749c9d500c81a4975b1599a872ebe8',
        backgroundColor: '#130123',
        textColor: '#fff',
      },
    ],
  },

  [Chain.Dfk]: {
    exchanges: [
      {
        slug: 'dex7cbaaa',
        isDefault: true,
        name: 'DeFi Kingdoms',
        logo: '/resources/exchanges/defikingdoms.svg',
        chainId: Chain.Dfk,
        urlInfo: 'https://defikingdoms.com/',
        urlSwap: 'https://game.defikingdoms.com',
        version: null as any,
        pairDefault: '0xcf329b34049033de26e4449aebcb41f1992724d3',
        backgroundColor: '#ffe3bd',
        textColor: '#100F21',
      },
    ],
  },

  [Chain.Solana]: {
    exchanges: [
      {
        slug: 'dexq59qw1',
        isDefault: false,
        name: 'Orca V1',
        logo: '/resources/exchanges/orca.svg',
        chainId: Chain.Solana,
        urlInfo: 'https://www.orca.so/stats',
        urlSwap: 'https://www.orca.so',
        version: 'v1',
        pairDefault: 'EGZ7tiLeH62TPV1gL8WwbXGzEPa9zmcpVnnkPKKnrE2U',
        backgroundColor: '#0d0f35',
        textColor: '#fff',
      },
      {
        slug: 'dexdp3aqp',
        isDefault: true,
        name: 'Orca V2',
        logo: '/resources/exchanges/orca.svg',
        chainId: Chain.Solana,
        urlInfo: 'https://www.orca.so/stats',
        urlSwap: 'https://www.orca.so',
        version: 'v2',
        pairDefault: 'EGZ7tiLeH62TPV1gL8WwbXGzEPa9zmcpVnnkPKKnrE2U',
        backgroundColor: '#0d0f35',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Evmos]: {
    exchanges: [
      {
        slug: 'dex523cd2',
        isDefault: true,
        name: 'Diffusion V1',
        logo: '/resources/exchanges/diffusion.svg',
        chainId: Chain.Evmos,
        urlInfo: 'https://diffusion.fi/',
        urlSwap: 'https://app.diffusion.fi/#/swap',
        version: 'v1',
        pairDefault: '0x6df94e977d82d917a621dff75c00abd19a27c26b',
        backgroundColor: '#05111a',
        textColor: '#27d2ea',
      },
    ],
  },
  [Chain.Doge]: {
    exchanges: [
      {
        slug: 'dex29c9c3',
        isDefault: true,
        name: 'DogeSwap',
        logo: '/resources/exchanges/dogeswap.png',
        chainId: Chain.Doge,
        urlInfo: 'https://dogeswap.org/',
        urlSwap: 'https://dogeswap.org/#/swap',
        version: null as any,
        pairDefault: '0x1352ea1afc5d4913ae0bab5a540a9f91299bebee',
        backgroundColor: '#ff007a',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Canto]: {
    exchanges: [
      {
        slug: 'dexc1cfba',
        isDefault: true,
        name: 'Slingshot',
        logo: '/resources/exchanges/slingshotfinance.svg',
        chainId: Chain.Canto,
        urlInfo: 'https://slingshot.finance/',
        urlSwap: 'https://app.slingshot.finance/trade/ETH',
        version: null as any,
        pairDefault: '0x9571997a66d63958e1b3de9647c22bd6b9e7228c',
        backgroundColor: '#66FF9A',
        textColor: '#000000',
      },
    ],
  },
  [Chain.Etc]: {
    exchanges: [
      {
        slug: 'dex66e4b0',
        isDefault: true,
        name: 'Soyfinance',
        logo: '/resources/exchanges/soyfinance.svg',
        chainId: Chain.Etc,
        urlInfo: 'https://soy.finance/',
        urlSwap: 'https://app.soy.finance/swap#/swap',
        version: null as any,
        pairDefault: '0xfb6414f689e93bacd942919fba7cd8aa7f5e5c64',
        backgroundColor: '#8ba333',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Gnosis]: {
    exchanges: [
      {
        slug: 'dex0737d7',
        isDefault: true,
        name: 'Honeyswap',
        logo: '/resources/exchanges/honeyswap.svg',
        logoStyles: '{ background-color: #2C3437 }',
        chainId: Chain.Gnosis,
        urlInfo: 'https://honeyswap.org/',
        urlSwap: 'https://honeyswap.org/',
        version: null as any,
        pairDefault: '0x176fb4db2d823907a4afede3df08746d2c8dd158',
        backgroundColor: '#40444f',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Bitgert]: {
    exchanges: [
      {
        slug: 'dex31c13f',
        isDefault: true,
        name: 'Icecreamswap',
        logo: '/resources/exchanges/icecreamswap.svg',
        chainId: Chain.Bitgert,
        urlInfo: 'https://app.icecreamswap.com/#/',
        urlSwap: 'https://app.icecreamswap.com/#/',
        version: null as any,
        pairDefault: '0x8c243d7b04e0f8f78dc87c8c2297581310468129',
        backgroundColor: '#FF007A',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Arbitrumnova]: {
    exchanges: [
      {
        slug: 'dexbc74c4',
        isDefault: true,
        name: 'Sushiswap',
        logo: '/resources/exchanges/sushiswap.svg',
        chainId: Chain.Bitgert,
        urlInfo: SUSHISWAP_URL,
        urlSwap: SUSHISWAP_URL_SWAP,
        version: null as any,
        pairDefault: '0x26d96cfc6df8262b1bb327a2df0ede02fdfc8874',
        backgroundColor: '#ef8220',
        textColor: '#000',
      },
    ],
  },
  // [Chain.Songbird]: {
  //   exchanges: [
  //     {
  //       slug: 'dex774fb6',
  //       isDefault: true,
  //       name: 'Flrx',
  //       logo: '/resources/exchanges/flrx.svg',
  //       chainId: Chain.Songbird,
  //       urlInfo: 'https://xfx.flr.finance/',
  //       urlSwap: 'https://xfx.flr.finance/',
  //       version: null as any,
  //       pairDefault: '0x47c830e141234d029d953df39b13d7728eb9f2d4',
  //       backgroundColor: '#201D21',
  //       textColor: '#e2665c',
  //     },
  //   ],
  // },
  [Chain.Redlight]: {
    exchanges: [
      {
        slug: 'dex6f8921',
        isDefault: true,
        name: 'Dawnswap',
        logo: '/resources/exchanges/dawnswapfinance.svg',
        chainId: Chain.Redlight,
        urlInfo: 'https://dawnswap.finance/farms',
        urlSwap: 'https://dawnswap.finance/swap',
        version: null as any,
        pairDefault: '0x46f013d0aa26d12c39b1c9dfdf67fcd05a452e52',
        backgroundColor: '#000000',
        textColor: '#ff3c3b',
      },
    ],
  },
  [Chain.Kardiachain]: {
    exchanges: [
      {
        slug: 'dexf50da1',
        isDefault: true,
        name: 'Kaidex',
        logo: '/resources/exchanges/kaidex.svg',
        chainId: Chain.Kardiachain,
        urlInfo: 'https://kaidex.io/statistic',
        urlSwap: 'https://kaidex.io/trade',
        version: null as any,
        pairDefault: '0x1c107492b20b12cd384e193b4426da97354cbea3',
        backgroundColor: '#f0ff35',
        textColor: '#262626',
      },
    ],
  },
  [Chain.Smartbch]: {
    exchanges: [
      {
        slug: 'dex265ca0',
        isDefault: true,
        name: 'Mistswap',
        logo: '/resources/exchanges/mistswap.svg',
        chainId: Chain.Smartbch,
        urlInfo: 'https://analytics.mistswap.fi/',
        urlSwap: 'https://app.mistswap.fi/swap',
        version: null as any,
        pairDefault: '0x27580618797a2ce02fdfbbee948388a50a823611',
        backgroundColor: '#142e4a',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Conflux]: {
    exchanges: [
      {
        slug: 'dex3342f5',
        isDefault: true,
        name: 'Swappi',
        logo: '/resources/exchanges/swappi.svg',
        chainId: Chain.Conflux,
        urlInfo: 'https://swappi.io/',
        urlSwap: 'https://app.swappi.io/#/swap',
        version: null as any,
        pairDefault: '0x8fcf9c586d45ce7fcf6d714cb8b6b21a13111e0b',
        backgroundColor: '#163b56',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Tomb]: {
    exchanges: [
      {
        slug: 'dex0703f9',
        isDefault: true,
        name: 'Lif3',
        logo: '/resources/exchanges/lif3.svg',
        chainId: Chain.Tomb,
        urlInfo: 'https://lif3.com/garden',
        urlSwap: 'https://lif3.com/swap',
        version: null as any,
        pairDefault: '0xd78372c98386c01e8f4abb2c5cdf8c234a99cf06',
        backgroundColor: '#d5b78a',
        textColor: '#38404e',
      },
    ],
  },
  [Chain.Boba]: {
    exchanges: [
      {
        slug: 'dex265d29',
        isDefault: true,
        name: 'Oolongswap',
        logo: '/resources/exchanges/oolongswap.svg',
        chainId: Chain.Boba,
        urlInfo: 'https://info.oolongswap.com/#/home',
        urlSwap: 'https://oolongswap.com/#/swap',
        version: null as any,
        pairDefault: '0xa43159b18ff8208bc3176a10af22c8167531769e',
        backgroundColor: '#e0efdd',
        textColor: '#000',
      },
    ],
  },
  [Chain.Elastos]: {
    exchanges: [
      {
        slug: 'dex5334e5',
        isDefault: true,
        name: 'Glide Finance',
        logo: '/resources/exchanges/glidefinance.svg',
        chainId: Chain.Elastos,
        urlInfo: 'https://glidefinance.io/info',
        urlSwap: 'https://glidefinance.io/swap',
        version: null as any,
        pairDefault: '0x6077b7990d3d0dfb5a50f1d207f67ac5955b999d',
        backgroundColor: '#f1a35b',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Shiden]: {
    exchanges: [
      {
        slug: 'dexad46b1',
        isDefault: true,
        name: 'Polkaex',
        logo: '/resources/exchanges/polkaex.svg',
        chainId: Chain.Shiden,
        urlInfo: 'https://app.polkaex.io/farms',
        urlSwap: 'https://app.polkaex.io/swap',
        version: null as any,
        pairDefault: '0x1f582584c003c9ba9258d1ea6c5289e617a169bf',
        backgroundColor: '#d20063',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Fusion]: {
    exchanges: [
      {
        slug: 'dexe4788f',
        isDefault: true,
        name: 'Chainge Finance',
        logo: '/resources/exchanges/chaingefinance.svg',
        chainId: Chain.Fusion,
        urlInfo: 'https://www.chainge.finance/info/pairs',
        urlSwap: 'https://www.chainge.finance/info/assets',
        version: null as any,
        pairDefault: '0x5df4e8cc088d997eb5fe475c28c621db18ea0787',
        backgroundColor: '#ffda00',
        textColor: '#000',
      },
    ],
  },
  [Chain.Wan]: {
    exchanges: [
      {
        slug: 'dexaa5537',
        isDefault: true,
        name: 'WanSwap',
        logo: '/resources/exchanges/wanswap.svg',
        chainId: Chain.Wan,
        urlInfo: 'https://www.wanswap.finance/#/farm',
        urlSwap: 'https://www.wanswap.finance/#/swap',
        version: null as any,
        pairDefault: '0x808035f3207c33ff3db27798f0af2946796c1aa1',
        backgroundColor: '#ffe600',
        textColor: '#46432e',
      },
    ],
  },
  [Chain.Rsk]: {
    exchanges: [
      {
        slug: 'dex77c0e1',
        isDefault: true,
        name: 'RSK Swap',
        logo: '/resources/exchanges/rskswap.svg',
        chainId: Chain.Rsk,
        urlInfo: 'https://rskswap.com/',
        urlSwap: 'https://app.rskswap.com/',
        version: null as any,
        pairDefault: '0x1dd8665ec5f47416ff37beb2de5cf7b095d377ad',
        backgroundColor: '#04b43c',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Nova]: {
    exchanges: [
      {
        slug: 'dex52bf55',
        isDefault: true,
        name: 'Fusion',
        logo: '/resources/exchanges/fusion.svg',
        chainId: Chain.Nova,
        urlInfo: 'https://fusion.novanetwork.io/#/',
        urlSwap: 'https://fusion.novanetwork.io/#/swap',
        version: null as any,
        pairDefault: '0x0775f696f64c824471e29460fb92694a41e754cf',
        backgroundColor: '#b24141',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Hoo]: {
    exchanges: [
      {
        slug: 'dex7313ea',
        isDefault: true,
        name: 'Elkfinance',
        logo: '/resources/exchanges/elkfinance.svg',
        chainId: Chain.Hoo,
        urlInfo: 'https://elk.finance/',
        urlSwap: 'https://app.elk.finance/#/swap',
        version: null as any,
        pairDefault: '0x4d5d0fb1b7744264293a35903a25783a4b577831',
        backgroundColor: '#009f54',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Cube]: {
    exchanges: [
      {
        slug: 'dexbf280f',
        isDefault: true,
        name: 'Capricorn',
        logo: '/resources/exchanges/capricorn.svg',
        chainId: Chain.Cube,
        urlInfo: 'https://www.capricorn.finance/',
        urlSwap: 'https://www.capricorn.finance/swap',
        version: null as any,
        pairDefault: '0x5d3ad1fe9beee77167033df7e9f43020f1071e41',
        backgroundColor: '#1da3ff',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Syscoin]: {
    exchanges: [
      {
        slug: 'dex021496',
        isDefault: true,
        name: 'Pegasys',
        logo: '/resources/exchanges/pegasys.svg',
        chainId: Chain.Syscoin,
        urlInfo: 'https://pegasys.finance/',
        urlSwap: 'https://app.pegasys.finance/#/swap',
        version: null as any,
        pairDefault: '0x2cdf912cbeaf76d67feadc994d889c2f4442b300',
        backgroundColor: '#1c2f49',
        textColor: '#2bb5cb',
      },
    ],
  },
  [Chain.Ronin]: {
    exchanges: [
      {
        slug: 'dex9cb930',
        isDefault: true,
        name: 'Katana',
        logo: '/resources/exchanges/katana.svg',
        chainId: Chain.Ronin,
        urlInfo: 'https://katana.roninchain.com/#/farm',
        urlSwap: 'https://katana.roninchain.com/#/swap',
        version: null as any,
        pairDefault: '0xa7964991f339668107e2b6a6f6b8e8b74aa9d017',
        backgroundColor: '#1886f0',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Viction]: {
    exchanges: [
      {
        slug: 'dex1447e8',
        isDefault: true,
        name: 'Baryon Network',
        logo: '/resources/exchanges/Baryon%20Network.webp',
        chainId: Chain.Viction,
        urlInfo: 'https://www.baryon.network/',
        urlSwap: 'https://www.baryon.network/swap',
        version: null as any,
        pairDefault: '0x347f551eaba062167779c9c336aa681526857b81',
        backgroundColor: '#fabc44',
        textColor: '#242828',
      },
    ],
  },
  [Chain.Kava]: {
    exchanges: [
      {
        slug: 'dex1b1f9f',
        isDefault: true,
        name: 'Surfswap',
        logo: '/resources/exchanges/surfswap.svg',
        chainId: Chain.Kava,
        urlInfo: 'https://surfdex.io/',
        urlSwap: 'https://app.surfdex.io/exchange/swap',
        version: null as any,
        pairDefault: '0x6bbebe57397d530fb95c69bd58ac0932d9bd39eb',
        backgroundColor: '#26a1a9',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Thundercore]: {
    exchanges: [
      {
        slug: 'dex00b46b',
        isDefault: true,
        name: 'Laserswap',
        logo: '/resources/exchanges/laserswap.svg',
        chainId: Chain.Thundercore,
        urlInfo: 'https://laserswap.finance/',
        urlSwap: 'https://laserswap.finance/swap',
        version: null as any,
        pairDefault: '0xa192acf29aebd0c94cad757a695812bf34d1e265',
        backgroundColor: '#2fb798',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Meter]: {
    exchanges: [
      {
        slug: 'dex193135',
        isDefault: true,
        name: 'Voltswap',
        logo: '/resources/exchanges/voltswap.svg',
        chainId: Chain.Meter,
        urlInfo: 'https://voltswap.finance/#/farm',
        urlSwap: 'https://voltswap.finance',
        version: null as any,
        pairDefault: '0xaca210bd7d12c15560994e4c7b2bec1b538ad306',
        backgroundColor: '#e60d7e',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Echelon]: {
    exchanges: [
      {
        slug: 'dexac680d',
        isDefault: true,
        name: 'Defyswap',
        logo: '/resources/exchanges/defyswap.svg',
        chainId: Chain.Echelon,
        urlInfo: 'https://defyswap.finance/',
        urlSwap: 'https://defyswap.finance/swap',
        version: null as any,
        pairDefault: '0xece49b38d9fe03756dc348ba7844d13be77c0909',
        backgroundColor: '#7645d9',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Shib]: {
    exchanges: [
      {
        slug: 'dex6798a6',
        isDefault: true,
        name: 'Shibswap',
        logo: '/resources/exchanges/shibswap.svg',
        chainId: Chain.Shib,
        urlInfo: 'https://shibswap.shibchain.app/#/pool',
        urlSwap: 'https://shibswap.shibchain.app/#/swap',
        version: null as any,
        pairDefault: '0x063c6d432037a6c9345738b9525e6b5fbf6ceaf7',
        backgroundColor: '#e40dce',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Ethw]: {
    exchanges: [
      {
        slug: 'dexc5aa6f',
        isDefault: true,
        name: 'Uniswap',
        logo: '/resources/exchanges/uniswap.svg',
        chainId: Chain.Ethw,
        urlInfo: UNISWAP_URL,
        urlSwap: UNISWAP_URL_SWAP,
        version: null as any,
        pairDefault: '0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc',
        backgroundColor: '#e8006f',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Kek]: {
    exchanges: [
      {
        slug: 'dex6a4dc4',
        isDefault: true,
        name: 'Kekswap',
        logo: '/resources/exchanges/kekswap.svg',
        chainId: Chain.Kek,
        urlInfo: 'https://keeekswap.com/#/swap',
        urlSwap: 'https://keeekswap.com/#/swap',
        version: null as any,
        pairDefault: '0x769574c9cda37df69043fb12221c97bf7fd0bafa',
        backgroundColor: '#0cee1e',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Dis]: {
    exchanges: [
      {
        slug: 'dexc5aa6f',
        isDefault: true,
        name: 'Uniswap',
        logo: UNISWAP_LOGO_URL,
        chainId: Chain.Dis,
        urlInfo: UNISWAP_URL,
        urlSwap: UNISWAP_URL_SWAP,
        version: null as any,
        pairDefault: '0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc',
        backgroundColor: '#ff2d79',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Sx]: {
    exchanges: [
      {
        slug: 'dex14c2cf',
        isDefault: true,
        name: 'Sharkswap',
        logo: '/resources/exchanges/sharkswap.png',
        chainId: Chain.Sx,
        urlInfo: 'https://www.sharkswap.xyz/swap',
        urlSwap: 'https://www.sharkswap.xyz/swap',
        version: null as any,
        pairDefault: '0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc', //TODO
        backgroundColor: '#3b82f6',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Muu]: {
    exchanges: [
      {
        slug: 'dex8a6edc',
        isDefault: true,
        name: 'Barnswap',
        logo: '/resources/exchanges/barnswap.svg',
        chainId: Chain.Muu,
        urlInfo: 'https://barnswap.com/#/swap',
        urlSwap: 'https://barnswap.com/#/swap',
        version: null as any,
        pairDefault: '0x8d9db48f288ccd089a68e978ec162fcfb14e3e41',
        backgroundColor: '#ad1925',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Alvey]: {
    exchanges: [
      {
        slug: 'dex4d8dab',
        isDefault: true,
        name: 'Elvesdex',
        logo: '/resources/exchanges/elvesdex.svg',
        chainId: Chain.Alvey,
        urlInfo: 'https://elvesdex.app',
        urlSwap: 'https://elvesdex.app',
        version: null as any,
        pairDefault: '0x7faf0ce59ed8bf87ed19cbee59318bd3ca55f7fa',
        backgroundColor: '#487cb6',
        textColor: '#fff',
      },
    ],
  },
  [Chain.Aptos]: {
    exchanges: [
      {
        slug: 'dex561e12',
        isDefault: true,
        name: 'Liquidswap',
        logo: '/resources/exchanges/liquidswap.png',
        chainId: Chain.Aptos,
        urlInfo: 'https://liquidswap.com/#/',
        urlSwap: 'https://liquidswap.com/#/',
        version: null as any,
        pairDefault:
          '0x190d44266241744264b964a37b8f09863167a12d3e70cda39376cfb4e3561e12<0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDC-0x1::aptos_coin::AptosCoin-0x190d44266241744264b964a37b8f09863167a12d3e70cda39376cfb4e3561e12::curves::Uncorrelated>',
        backgroundColor: '#6e42ca',
        textColor: '#fff',
      },
    ],
  },

  [Chain.Multiversx]: {
    exchanges: [
      {
        slug: 'dex2fy57p',
        isDefault: true,
        name: 'xexchange',
        logo: '/resources/exchanges/xexchange.png',
        chainId: Chain.Multiversx,
        urlInfo: 'https://xexchange.com/',
        urlSwap: 'https://xexchange.com/swap/',
        version: null as any,
        pairDefault: 'erd1qqqqqqqqqqqqqpgqeel2kumf0r8ffyhth7pqdujjat9nx0862jpsg2pqaq',
        backgroundColor: '#3364f6',
        textColor: '#fff',
      },
    ],
  },

  [Chain.Flare]: {
    exchanges: [
      {
        slug: 'dex43d79a',
        isDefault: true,
        name: 'Pangolin',
        logo: '/resources/exchanges/pangolin.png',
        chainId: Chain.Flare,
        urlInfo: 'https://app.pangolin.exchange/#/dashboard',
        urlSwap: 'https://app.pangolin.exchange/#/swap',
        version: null as any,
        pairDefault: '0xff1b852a0582bf87e69fad114560595fc5cf1212',
        backgroundColor: '#ffc800',
        textColor: '#000000',
      },
    ],
  },

  [Chain.Pom]: {
    exchanges: [
      {
        slug: 'dex93caee',
        isDefault: true,
        name: 'POMSwap',
        logo: '/resources/chains/med/pom.png',
        chainId: Chain.Pom,
        urlInfo: 'https://www.pomswap.org/#/swap',
        urlSwap: 'https://www.pomswap.org/#/swap',
        version: null as any,
        pairDefault: '0x0282a441addc2c6a0d1d0cd74469338be405b658',
        backgroundColor: '#1e1b57',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.Ultron]: {
    exchanges: [
      {
        slug: 'dexcf00b7',
        isDefault: true,
        name: 'UltronSwap',
        logo: '/resources/chains/med/ultron.png',
        chainId: Chain.Ultron,
        urlInfo: 'https://ultronswap.com/swap',
        urlSwap: 'https://ultronswap.com/swap',
        version: null as any,
        pairDefault: '0x938ba76720683be80c1d3deffa27b9153e48c5e9',
        backgroundColor: '#150b42',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.Energi]: {
    exchanges: [
      {
        slug: 'dexf594e4',
        isDefault: true,
        name: 'EnergiSwap',
        logo: '/resources/chains/med/energi.png',
        chainId: Chain.Energi,
        urlInfo: 'https://app.energiswap.exchange/',
        urlSwap: 'https://app.energiswap.exchange/',
        version: null as any,
        pairDefault: '0x486b881f845532454ee1c4b695a1da9ae7e97c09',
        backgroundColor: '#00e676',
        textColor: '#000000',
      },
    ],
  },

  [Chain.Exosama]: {
    exchanges: [
      {
        slug: 'dex96d271',
        isDefault: true,
        name: 'Khaos',
        logo: '/resources/chains/med/khaos.png',
        chainId: Chain.Pom,
        urlInfo: 'https://khaos.exchange/',
        urlSwap: 'https://khaos.exchange/',
        version: null as any,
        pairDefault: '0x7f46ed28ca9f47a3f63dc52fb2300d171d8eb9a0',
        backgroundColor: '#171923',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.CoreDao]: {
    exchanges: [
      {
        slug: 'dexa4d47c',
        isDefault: true,
        name: 'Icecreamswap',
        logo: '/resources/chains/med/icecreamswap.png',
        chainId: Chain.CoreDao,
        urlInfo: 'https://icecreamswap.com/?chainId=1116',
        urlSwap: 'https://icecreamswap.com/swap?chainId=1116',
        version: null as any,
        pairDefault: '0x5ebae3a840ff34b107d637c8ed07c3d1d2017178',
        backgroundColor: '#F8567F',
        textColor: '#ffffff',
      },
    ],
  },
  [Chain.EthereumGoerli]: {
    exchanges: [
      {
        slug: 'dex84e28e',
        isDefault: true,
        name: 'Uniswap',
        logo: UNISWAP_LOGO_URL,
        chainId: Chain.EthereumGoerli,
        urlInfo: UNISWAP_URL,
        urlSwap: UNISWAP_URL_SWAP,
        version: null as any,
        pairDefault: '0x00b64e468d2c705a0907f58505536a6c8c49ab26',
        backgroundColor: '#ff2d79',
        textColor: '#fff',
      },
    ],
  },

  [Chain.Filecoin]: {
    exchanges: [
      {
        slug: 'abcdf',
        isDefault: true,
        name: EXCHANGE_UNKNOWN,
        logo: '/resources/exchanges/unknown.png',
        chainId: Chain.Filecoin,
        urlInfo: '#',
        urlSwap: '#',
        version: null as any,
        pairDefault: '0xdfaab828f5f515e104baaba4d8d554da9096f0e4',
        backgroundColor: '#111',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.Zksync]: {
    exchanges: [
      {
        slug: 'dex60627d',
        isDefault: true,
        name: 'Mute',
        logo: '/resources/exchanges/muteio.png',
        chainId: Chain.Zksync,
        urlInfo: 'https://mute.io/',
        urlSwap: 'https://app.mute.io',
        version: null as any,
        pairDefault: '0xe939c9eda1df1555d0ae2230d85184e71b337743',
        backgroundColor: '#142028',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.Polygonzkevm]: {
    exchanges: [
      {
        slug: 'dexc43b57',
        isDefault: true,
        name: 'Quickswap V3',
        logo: '/resources/exchanges/quickswap.png',
        chainId: Chain.Polygonzkevm,
        urlInfo: 'https://info.quickswap.exchange/',
        urlSwap: 'https://quickswap.exchange/#/',
        version: 'v3',
        pairDefault: '0xc44ad482f24fd750caeba387d2726d8653f8c4bb',
        backgroundColor: '#8141f2',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.Pulse]: {
    exchanges: [
      {
        slug: 'dexeba10d',
        isDefault: true,
        name: '',
        logo: '/resources/exchanges/pulsex.png',
        chainId: Chain.Pulse,
        urlInfo: 'https://pulsex.com/',
        urlSwap: 'https://app.pulsex.com/swap',
        version: null as any,
        pairDefault: '0xe56043671df55de5cdf8459710433c10324de0ae',
        backgroundColor: '#0e8e4b',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.Klaytn]: {
    exchanges: [
      {
        slug: 'dex9e7654',
        isDefault: true,
        name: 'klayswap',
        logo: '/resources/exchanges/klayswap.png',
        chainId: Chain.Klaytn,
        urlInfo: 'https://klayswap.com/',
        urlSwap: 'https://klayswap.com/exchange/swap',
        version: null as any,
        pairDefault: '0xd83f1b074d81869eff2c46c530d7308ffec18036',
        backgroundColor: '#ff2f00',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.Linea]: {
    exchanges: [
      {
        slug: 'dexe9be8c',
        isDefault: true,
        name: 'Leetswap',
        logo: '/resources/exchanges/leetswap.png',
        chainId: Chain.Linea,
        urlInfo: 'https://linea.leetswap.finance/#/swap',
        urlSwap: 'https://linea.leetswap.finance/#/swap',
        version: null as any,
        pairDefault: '0xc5ff010aefbac255f5e2251660794feb4638191e',
        backgroundColor: '#22c55e',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.Base]: {
    exchanges: [
      {
        slug: 'dexbc74c4',
        isDefault: true,
        name: 'Sushiswap',
        logo: SUSHISWAP_LOGO_URL,
        chainId: Chain.Base,
        urlInfo: SUSHISWAP_URL,
        urlSwap: SUSHISWAP_URL_SWAP,
        version: null as any,
        pairDefault: '0xaca85874d52e3e6d991f9e0b273a96228edfee7b',
        backgroundColor: '#1e2025',
        textColor: '#ffffff',
      },
    ],
  },
  [Chain.Mantle]: {
    exchanges: [
      {
        slug: 'dexbc74c4',
        isDefault: true,
        name: 'Fusion X',
        logo: '/resources/exchanges/fusionx.png',
        chainId: Chain.Base,
        urlInfo: 'ttps://fusionx.finance/',
        urlSwap: 'https://fusionx.finance/swap',
        version: null as any,
        pairDefault: '0x262255f4770aebe2d0c8b97a46287dcecc2a0aff',
        backgroundColor: '#1a443a',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.Bitrock]: {
    exchanges: [
      {
        slug: 'dexaa86a9',
        isDefault: true,
        name: 'Bitrock Dext',
        logo: '/resources/exchanges/bitrock.png',
        chainId: Chain.Bitrock,
        urlInfo: 'https://rock-swap.io/#/swap',
        urlSwap: 'https://rock-swap.io/#/swap',
        version: null as any,
        pairDefault: '0x81102905f6d6a045ccb83ff2cae6d4542ab06751',
        backgroundColor: '#28a745',
        textColor: '#000000',
      },
    ],
  },

  [Chain.Opbnb]: {
    exchanges: [
      {
        slug: 'dexd25911',
        isDefault: true,
        name: 'Binary Swap',
        logo: '/resources/exchanges/binaryswap.png',
        chainId: Chain.Opbnb,
        urlInfo: 'https://www.binaryswap.io/#/swap',
        urlSwap: 'https://www.binaryswap.io/#/swap',
        version: null as any,
        pairDefault: '0x506b8322e1159d06e493ebe7ffa41a24291e7ae3',
        backgroundColor: '#34A958',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.Shibarium]: {
    exchanges: [
      {
        slug: 'dex5ce204',
        isDefault: true,
        name: 'Piswap',
        logo: '/resources/exchanges/piswap.png',
        chainId: Chain.Shibarium,
        urlInfo: 'https://piswap.exchange/',
        urlSwap: 'https://piswap.exchange/',
        version: null as any,
        pairDefault: '0x38c5dDB9903ef9227bB47B1319BD918d986db944',
        backgroundColor: '#191921',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.Starknet]: {
    exchanges: [
      {
        slug: 'dex60627d',
        isDefault: true,
        name: 'JediSwap',
        logo: '/resources/exchanges/jediswap.png',
        chainId: Chain.Starknet,
        urlInfo: 'https://app.jediswap.xyz/',
        urlSwap: 'https://app.jediswap.xyz/#/swap',
        version: null as any,
        pairDefault: '0x000023c72abdf49dffc85ae3ede714f2168ad384cc67d08524732acea90df325',
        backgroundColor: '#0c0c4f',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.Scroll]: {
    exchanges: [
      {
        slug: 'dex153e90',
        isDefault: true,
        name: 'Punkswap',
        logo: '/resources/exchanges/punkswap.png',
        chainId: Chain.Scroll,
        urlInfo: 'https://punkswap.exchange/',
        urlSwap: 'https://zkscroll.punkswap.exchange/swap/',
        version: null as any,
        pairDefault: '0x6905c59be1a7ea32d1f257e302401ec9a1401c52',
        backgroundColor: '#87e3d4',
        textColor: '#000000',
      },
    ],
  },

  [Chain.Manta]: {
    exchanges: [
      {
        slug: 'dex68a13a',
        isDefault: true,
        name: 'Apertureswap',
        logo: '/resources/exchanges/apertureswap.png',
        chainId: Chain.Manta,
        urlInfo: 'https://app.aperture.finance',
        urlSwap: 'https://app.aperture.finance/swap',
        version: null as any,
        pairDefault: '0xffeee0b83fd00032cb035b57a725c9670dfa6f2f',
        backgroundColor: '#6d6978',
        textColor: '#000000',
      },
    ],
  },
  [Chain.Kujira]: {
    exchanges: [
      {
        slug: 'dexra-fin',
        isDefault: true,
        name: 'FIN',
        logo: '/resources/exchanges/fin.webp',
        chainId: Chain.Kujira,
        urlInfo: 'https://fin.kujira.network/',
        urlSwap: 'https://fin.kujira.network/',
        version: null as any,
        pairDefault: 'kujira1pw96huy6z02uk8hdpruk6g8u700dp8yxjhp46c24rwkzay2lfd3quqdum5',
        backgroundColor: '#e53935',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.Blast]: {
    exchanges: [
      {
        slug: 'dex81d088',
        isDefault: true,
        name: 'HyperBlast',
        logo: '/resources/exchanges/Hyperblast.webp',
        chainId: Chain.Blast,
        urlInfo: 'https://app.hyperblast.io/',
        urlSwap: 'https://app.hyperblast.io/pools',
        version: null as any,
        pairDefault: '0x815fec7705238a2b00855a38a07f73bc464a59d6',
        backgroundColor: '#f5ff3c',
        textColor: '#000',
      },
    ],
  },

  [Chain.BitTorrent]: {
    exchanges: [
      {
        slug: 'dex76f741',
        isDefault: true,
        name: 'Spookyswap',
        logo: '/resources/exchanges/Spookyswap.webp',
        chainId: Chain.BitTorrent,
        urlInfo: 'https://info.btt.spooky.fi/',
        urlSwap: 'https://spooky.fi/#/?chain=bit_torrent',
        version: null as any,
        pairDefault: '0x418afcd6d15fa51d59d6c7f7a5943eb04d5f21f0',
        backgroundColor: '#3e3e7c',
        textColor: '#fff',
      },
    ],
  },

  [Chain.Osmosis]: {
    exchanges: [
      {
        slug: 'dexsmosis',
        isDefault: true,
        name: 'Osmosis',
        logo: '/resources/exchanges/osmosis.png',
        chainId: Chain.Osmosis,
        urlInfo: 'https://app.osmosis.zone',
        urlSwap: 'https://app.osmosis.zone/',
        version: null as any,
        pairDefault: '678',
        backgroundColor: '#452adf',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.XLayer]: {
    exchanges: [
      {
        slug: 'dex1ebb08',
        isDefault: true,
        name: 'XLayer',
        logo: '/resources/exchanges/xlayer.png',
        chainId: Chain.XLayer,
        urlInfo: 'https://izumi.finance',
        urlSwap: 'https://izumi.finance/trade/swap',
        version: null as any,
        pairDefault: '0x17afee25551ad0ac25f0e040b2e520e29b9f108a',
        backgroundColor: '#32a9c9',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.ShimmerEvm]: {
    exchanges: [
      {
        slug: 'dexd5ac47',
        isDefault: true,
        name: 'ShimmerSea',
        logo: '/resources/exchanges/shimmersea.png',
        chainId: Chain.ShimmerEvm,
        urlInfo: 'https://shimmersea.finance/liquidity',
        urlSwap: 'https://shimmersea.finance/swap',
        version: null as any,
        pairDefault: '0xa3e6fbf50a05e6033ed48adc2351c6099b0b2499',
        backgroundColor: '#0c0032',
        textColor: '#00e9f9',
      },
    ],
  },
  [Chain.Mode]: {
    exchanges: [
      {
        slug: 'dexcd4500',
        isDefault: true,
        name: 'Kim',
        logo: '/resources/exchanges/kim.png',
        chainId: Chain.Mode,
        urlInfo: 'https://www.kim.exchange',
        urlSwap: 'https://app.kim.exchange/swap',
        version: null as any,
        pairDefault: '0x293f2b2c17f8cea4db346d87ef5712c9dd0491ef',
        backgroundColor: '#ff4500',
        textColor: '#ffffff',
      },
    ],
  },

  [Chain.Ton]: {
    exchanges: [
      {
        slug: 'dex07384e',
        isDefault: true,
        name: 'Ston.fi',
        logo: '/resources/exchanges/stonfi.png',
        chainId: Chain.Ton,
        urlInfo: 'https://ston.fi',
        urlSwap: 'https://app.ston.fi/swap?chartVisible=false',
        version: null as any,
        pairDefault: 'EQD8TJ8xEWB1SpnRE4d89YO3jl0W0EiBnNS4IBaHaUmdfizE',
        backgroundColor: '#3376d8',
        textColor: '#ffffff',
      },
    ],
  },
  [Chain.Hedera]: {
    exchanges: [
      {
        slug: 'dex103780',
        isDefault: true,
        name: 'Saucerswap',
        logo: '/resources/exchanges/saucerswap.png',
        chainId: Chain.Hedera,
        urlInfo: 'https://www.saucerswap.finance',
        urlSwap: 'https://www.saucerswap.finance/swap',
        version: null as any,
        pairDefault: '0xdb34c1ef944883f0e5a2fc18b6c1978b088bd31d',
        backgroundColor: '#0d2132',
        textColor: '#6afd52',
      },
    ],
  },
  [Chain.Near]: {
    exchanges: [
      {
        slug: 'dexe.near',
        isDefault: true,
        name: 'Ref. finance',
        logo: '/resources/exchanges/ref-finance.webp',
        chainId: Chain.Near,
        urlInfo: 'https://www.ref.finance',
        urlSwap: 'https://app.ref.finance',
        version: null as any,
        pairDefault: '0x293f2b2c17f8cea4db346d87ef5712c9dd0491ef',
        backgroundColor: '#00c6a2',
        textColor: '#ffffff',
      },
    ],
  },
  [Chain.Tron]: {
    exchanges: [
      {
        slug: 'dex4pmaay',
        isDefault: true,
        name: 'SunSwap V2',
        logo: '/resources/exchanges/SunSwap V2.webp',
        chainId: Chain.Tron,
        urlInfo: 'https://sunswap.com/#/v2',
        urlSwap: 'https://sunpump.meme/token/',
        version: null as any,
        pairDefault: 'TFGDbUyP8xez44C76fin3bn3Ss6jugoUwJ',
        backgroundColor: '#5915e1',
        textColor: '#ffffff',
      },
    ],
  },
};
